import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import useCreateOrderContext from "../../hooks/useCreateOrderContext";
import { Fragment } from "react";

import { componentTypes as types } from "../../utils/const";

import { ThemeProvider } from "@mui/material";
import { overrideTheme as theme } from "../StyledComponents/OverrideMuiTheme";

function ProductInfoForm(props) {
  const { packages, error, handleChange, handleDeleteProduct } =
    useCreateOrderContext();

  const { packageIndex } = props;

  const productEl = packages[packageIndex].goods.map(
    (goodsInfo, productIndex) => {
      return (
        <Fragment key={productIndex}>
          <Divider sx={{ width: "100%", marginBottom: "1.5rem" }} />
          <Grid item xs={12} sx={{ marginBottom: "1.5rem" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      focused
                      required
                      id="product_id"
                      label={`Product SKU`}
                      placeholder={`Product SKU`}
                      value={goodsInfo.goodsCode}
                      onChange={(e) =>
                        handleChange(
                          e,
                          types.GOODS_CODE,
                          packageIndex,
                          productIndex
                        )
                      }
                      error={
                        error.goodsErrors[packageIndex][productIndex][
                          "goodsCodeError"
                        ] && goodsInfo.goodsCode === ""
                      }
                      helperText={
                        error.goodsErrors[packageIndex][productIndex][
                          "goodsCodeError"
                        ] &&
                        goodsInfo.goodsCode === "" &&
                        "Product ID is required"
                      }
                    />
                    {/* <Typography variant="h6">{`Product ${
                      productIndex + 1
                    }`}</Typography> */}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      focused
                      id="product_name"
                      label="Product Name"
                      placeholder="Product Name"
                      value={goodsInfo.goodsName}
                      onChange={(e) =>
                        handleChange(
                          e,
                          types.GOODS_NAME,
                          packageIndex,
                          productIndex
                        )
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ maxHeight: "70%" }}
                      onClick={() =>
                        handleDeleteProduct(packageIndex, productIndex)
                      }
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  focused
                  required
                  id="product_count"
                  label="Product Count"
                  placeholder="Product Count"
                  value={goodsInfo.goodsCount}
                  onChange={(e) =>
                    handleChange(
                      e,
                      types.GOODS_COUNT,
                      packageIndex,
                      productIndex
                    )
                  }
                  error={
                    error.goodsErrors[packageIndex][productIndex][
                      "goodsCountError"
                    ] && goodsInfo.goodsCount === ""
                  }
                  helperText={
                    error.goodsErrors[packageIndex][productIndex][
                      "goodsCountError"
                    ] &&
                    goodsInfo.goodsCount === "" &&
                    "Product count is required"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  focused
                  id="product_origin"
                  label={`Product Origin (ISO 2 Characters)`}
                  placeholder={`Product Origin`}
                  value={goodsInfo.origin}
                  onChange={(e) =>
                    handleChange(
                      e,
                      types.GOODS_ORIGIN,
                      packageIndex,
                      productIndex
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  focused
                  id="product_worth"
                  label="Product Worth"
                  placeholder="Product Worth"
                  value={goodsInfo.worth}
                  onChange={(e) =>
                    handleChange(
                      e,
                      types.GOODS_WORTH,
                      packageIndex,
                      productIndex
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  focused
                  id="product_currency"
                  label="Product Currency"
                  placeholder="Product Currency"
                  value={goodsInfo.currency}
                  onChange={(e) =>
                    handleChange(
                      e,
                      types.GOODS_CURRENCY,
                      packageIndex,
                      productIndex
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  focused
                  id="product_weight"
                  label="Product Weight"
                  placeholder="Product Weight"
                  value={goodsInfo.weight}
                  onChange={(e) =>
                    handleChange(
                      e,
                      types.GOODS_WEIGHT,
                      packageIndex,
                      productIndex
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  focused
                  id="product_height"
                  label="Product Height"
                  placeholder="Product Height"
                  value={goodsInfo.height}
                  onChange={(e) =>
                    handleChange(
                      e,
                      types.GOODS_HEIGHT,
                      packageIndex,
                      productIndex
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  focused
                  id="product_length"
                  label="Product Length"
                  placeholder="Product Length"
                  value={goodsInfo.length}
                  onChange={(e) =>
                    handleChange(
                      e,
                      types.GOODS_LENGTH,
                      packageIndex,
                      productIndex
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  focused
                  id="product_width"
                  label="Product Width"
                  placeholder="Product Width"
                  value={goodsInfo.width}
                  onChange={(e) =>
                    handleChange(
                      e,
                      types.GOODS_WIDTH,
                      packageIndex,
                      productIndex
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      );
    }
  );

  return <ThemeProvider theme={theme}>{productEl}</ThemeProvider>;
}

export default ProductInfoForm;
