//react
import { useState } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

//pages
import SignInPage from "./pages/SignInPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import SignUpPage from "./pages/SignUpPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ShippingCalculator from "./pages/ShippingCalculator";
import CreateOrder from "./pages/CreateOrder";
import BatchOrder from "./pages/BatchOrder";
import NotFoundPage from "./pages/PageNotFound";
import Invoice from "./pages/Invoice";
import RateCard from "./pages/RateCard";
import AdminTable from "./pages/AdminTable";

//component
import PrivateRoute from "./components/PrivateRoute";

import { CreateOrderContextProvider } from "./components/Contexts/CreateOrderContext";
import { BatchOrderProvider } from "./components/Contexts/BatchOrderContext";

function App() {
  return (
    <BatchOrderProvider>
      <CreateOrderContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="sign-in" element={<SignInPage />} />
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route
              path={"/reset-password/:id"}
              element={<ResetPasswordPage />}
            />

            <Route
              path="/"
              element={
                <PrivateRoute>
                  <CreateOrder />
                </PrivateRoute>
              }
            />

            <Route
              path="shipping-calculator"
              element={
                <PrivateRoute>
                  <ShippingCalculator />
                </PrivateRoute>
              }
            />
            <Route
              path="create-order"
              element={
                <PrivateRoute>
                  <CreateOrder />
                </PrivateRoute>
              }
            />
            <Route
              path="batch-order"
              element={
                <PrivateRoute>
                  <BatchOrder />
                </PrivateRoute>
              }
            />
            <Route
              path="invoice"
              element={
                <PrivateRoute>
                  <Invoice />
                </PrivateRoute>
              }
            />
            <Route
              path="rate-card"
              element={
                <PrivateRoute>
                  <RateCard />
                </PrivateRoute>
              }
            />
            <Route
              path="admin-table"
              element={
                <PrivateRoute>
                  <AdminTable />
                </PrivateRoute>
              }
            />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </CreateOrderContextProvider>
    </BatchOrderProvider>
  );
}

export default App;
