import { Fragment } from "react";
import _ from "lodash";

import ups_logo from "./../../assets/images/ups-logo.png";
import usps_logo from "./../../assets/images/usps-logo.png";
import fedex_logo from "./../../assets/images/fedex-logo.png";

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Skeleton,
  Stack,
} from "@mui/material";

const carriers_logo = {
  ups: ups_logo,
  fedex: fedex_logo,
  usps: usps_logo,
  link_future: fedex_logo,
  cope: fedex_logo,
  gori: usps_logo,
  ddu: fedex_logo,
};

const carriers_name = {};

function RateItems(props) {
  const { rates, isLoading } = props;

  if (_.isEmpty(rates)) {
    return null;
  }

  console.log("rates", rates);

  return (
    <Fragment>
      {/* loop through all carriers */}
      {Object.keys(rates).map((key, index) => {
        return (
          <Grid item xs={12} sm={3} key={index}>
            <List key={index}>
              {/* loop through all services */}
              {rates[key].map((rate, index) => {
                if (
                  rate.service_name === "LAYA_FEDEX_HOME" ||
                  rate.service_name === "LAYA_FEDEX_HOME_SIGN"
                ) {
                  return;
                }

                if (rate.service_name === "LAYA_FEDEX_GROUND") {
                  rate.service_name = "LAYA_FEDEX";
                }

                if (rate.service_name === "LAYA_FEDEX_GROUND_SIGN") {
                  rate.service_name = "LAYA_FEDEX_SIGN";
                }

                return (
                  <Fragment key={index}>
                    <ListItem key={rate.service_code}>
                      {/* when loading, display skeleton loader */}
                      {isLoading && (
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={100}
                          height={57}
                        />
                      )}
                      {!isLoading && (
                        <img src={carriers_logo[key]} alt={key} width="100" />
                      )}
                      <Stack
                        spacing={1}
                        sx={{
                          width: "80%",
                          display: `${isLoading ? "block" : "none"}`,
                          marginLeft: "20px",
                        }}
                      >
                        {isLoading && (
                          <Skeleton animation="wave" height={20} width="50%" />
                        )}
                        {isLoading && (
                          <Skeleton animation="wave" height={20} width="70%" />
                        )}
                      </Stack>
                      {!isLoading && (
                        <ListItemText
                          primary={
                            rate.service_name || rate.carrier_id.toUpperCase()
                          }
                          secondary={`your rate: ${
                            !_.isEmpty(rate.packages) && rate?.packages[0]?.rate
                              ? `$${rate.packages[0].rate}`
                              : "N/A"
                          }`}
                        />
                      )}
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </Fragment>
                );
              })}
            </List>
          </Grid>
        );
      })}
    </Fragment>
  );
}

export default RateItems;
