import { useState, useEffect, useMemo } from "react";

import { MaterialReactTable } from "material-react-table";
import { fetchOrders, fetchLabels, cancelLabels } from "../services";
import {
  Box,
  Button,
  IconButton,
  ThemeProvider,
  Typography,
  createTheme,
  Modal,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Snackbar,
  Alert,
} from "@mui/material";

import useBatchOrderContext from "../hooks/useBatchOrderContext";
import useAuthContext from "../hooks/useAuthContext";

import PrintPDF from "./PrintPDF";

import PrintIcon from "@mui/icons-material/Print";
import RefreshIcon from "@mui/icons-material/Refresh";
import CancelIcon from "@mui/icons-material/Cancel";
import AssignmentIcon from "@mui/icons-material/Assignment";

import { PDFDocument } from "pdf-lib";
import ReactJson from "react-json-view";

import _ from "lodash";
import JSZip from "jszip";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  boxShadow: 24,
  bgcolor: "white",
  p: 8,
};

function OrderTable() {
  const [tableData, setTableData] = useState([]);
  const [showProgressBars, setShowProgressBars] = useState(false);
  const [showSkeletons, setShowSkeletons] = useState(false);
  const [currentLabel, setCurrentLabel] = useState(null);
  const [printTrigger, setPrintTrigger] = useState(0);
  const [batchLabelsModalOpen, setBatchLabelsModalOpen] = useState(false);
  const [labelOptions, setLabelOptions] = useState("single");
  const [labelNameOptions, setLabelNameOptions] = useState("tracking_num");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [downloadLabels, setDownloadLabels] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  const [isBackgroundFetching, setIsBackgroundFetching] = useState(false);
  const [backgroundData, setBackgroundData] = useState([]);
  const [cancellationToken, setCancellationToken] = useState(false);

  const { orders } = useBatchOrderContext();

  const { authentication, handleCurrentUser, cusID } = useAuthContext();

  const columns = useMemo(() => [
    {
      // accessorKey: "order_date",
      id: "order_date",
      accessorFn: (row) => {
        const originalDate = new Date(row.order_date);
        const year = originalDate.getFullYear();
        const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
        const day = originalDate.getDate().toString().padStart(2, "0");
        const hours = originalDate.getHours().toString().padStart(2, "0");
        const minutes = originalDate.getMinutes().toString().padStart(2, "0");
        const seconds = originalDate.getSeconds().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
      },
      header: "Order Date",
      size: 50,
      enableEditing: false,
    },
    {
      accessorKey: "customer_order_id",
      header: "Order ID",
      size: 50,
      enableEditing: false,
    },
    // {
    //   accessorKey: "platform_id",
    //   header: "Platform ID",
    //   size: 50,
    //   enableEditing: false,
    // },
    // {
    //   accessorKey: "carrier_id",
    //   header: "Carrier ID",
    //   size: 25,
    //   enableEditing: false,
    // },
    {
      accessorKey: "ordered_by",
      header: "Order By",
      size: 50,
      enableEditing: false,
    },
    {
      // accessorKey: "tracking_num",
      header: "Tracking Number",
      id: "tracking_num",
      accessorFn: (row) => {
        const masterTracking = row.tracking_num || "";
        let childTracking = row.children_tracking_num || "";
        childTracking = childTracking.split(",").join("\n");
        const fullTracking = `${masterTracking} \n ${childTracking}`;
        return fullTracking;
      },
      size: 50,
      enableEditing: false,
    },
    {
      id: "estimated_amount",
      accessorFn: (row) => {
        let estimated_amount = row.estimated_amount;
        estimated_amount = parseFloat(estimated_amount);
        if (!isNaN(estimated_amount)) {
          estimated_amount = estimated_amount.toFixed(2);
        }
        // console.log("estimated_amount", estimated_amount);
        return isNaN(estimated_amount) ? "N/A" : estimated_amount;
      },
      header: "Estimated Amount",
      size: 25,
      enableEditing: false,
    },
    {
      accessorKey: "adjusted_by",
      header: "Adjusted By",
      size: 25,
      enableEditing: false,
    },
    {
      accessorKey: "order_status",
      header: "Order Status",
      size: 50,
      enableEditing: false,
      Cell: ({ cell }) => (
        <Box
          sx={(theme) => ({
            backgroundColor:
              cell.getValue() === "success"
                ? theme.palette.success.dark
                : theme.palette.error.main,
            borderRadius: "0.25rem",
            p: "0.25rem",
            minWidth: "fit-content",
            maxWidth: "60px",
            display: "flex",
            justifyContent: "center",
          })}
        >
          {cell.getValue()}
        </Box>
      ),
    },
    {
      accessorKey: "refund_status",
      header: "Refund Status",
      size: 50,
      enableEditing: false,
      Cell: ({ cell }) => (
        <Box
          sx={(theme) => ({
            backgroundColor: `${
              cell.getValue() === "success"
                ? theme.palette.warning.main
                : cell.getValue() === "failed"
                ? theme.palette.error.main
                : ""
            }`,
            borderRadius: "0.25rem",
            p: "0.25rem",
            minWidth: "fit-content",
            maxWidth: "60px",
            display: "flex",
            justifyContent: "center",
          })}
        >
          {`${
            cell.getValue() === "success"
              ? "canceled"
              : cell.getValue() === "failed"
              ? "failed"
              : ""
          }`}
        </Box>
      ),
    },
    {
      id: "error_message",
      accessorFn: (row) =>
        JSON.stringify(row.error_message, null, 2) === "null"
          ? ""
          : JSON.stringify(row.error_message, null, 2),
      header: "Error Message",
      size: 25,
      enableEditing: false,
    },
  ]);

  const handleBatchLabelsModalOpen = () => setBatchLabelsModalOpen(true);
  const handleBatchLabelsModalClose = () => setBatchLabelsModalOpen(false);

  const handleLabelOptions = (event) => {
    setLabelOptions(event.target.value);
  };

  const handleLabelNameOptions = (event) => {
    setLabelNameOptions(event.target.value);
  };

  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handlePrintLabel = (base64PDF) => {
    handlePrintTrigger();
    setCurrentLabel(base64PDF);
  };

  const handleFetchOrders = () => {
    handleCurrentUser();
    setShowProgressBars(true);
    setShowSkeletons(true);
    const { pageIndex, pageSize } = pagination;
    fetchOrders(authentication, pageSize, pageIndex * pageSize).then(
      (response) => {
        // console.log("response", response);
        const responseData = response.data.data;
        const orderTotalLength = response.data?.total;
        setRowCount(orderTotalLength);
        // console.log("orderTotalLength", orderTotalLength);
        setTableData(responseData);
        setShowProgressBars(false);
        setShowSkeletons(false);
      }
    );
  };

  const handleInitialFetchOrders = () => {
    // console.log("handleInitialFetchOrders");
    handleCurrentUser();
    setShowProgressBars(true);
    setShowSkeletons(true);

    const { pageIndex, pageSize } = pagination;
    fetchOrders(authentication, pageSize, pageIndex * pageSize)
      .then((response) => {
        if (response && response.data) {
          const responseData = response.data.data;
          const orderTotalLength = response.data.total || 0;
          setRowCount(orderTotalLength);
          setTableData(responseData);
        }
        setShowProgressBars(false);
        setShowSkeletons(false);

        // Start background fetch for all orders after initial load
        setCancellationToken(false); // Reset cancellation flag
        handleFetchAllOrders();
      })
      .catch((error) => {
        console.error("Error fetching initial orders:", error);
        setShowProgressBars(false);
        setShowSkeletons(false);
      });
  };

  const handleFetchAllOrders = () => {
    if (isBackgroundFetching) return; // Avoid duplicate calls
    setIsBackgroundFetching(true);

    fetchOrders(authentication)
      .then((response) => {
        if (!cancellationToken && response && response.data) {
          const allOrders = response.data.data;
          setBackgroundData(allOrders); // Store full fetched data in background state
        }
        setIsBackgroundFetching(false);
        // console.log("handleFetchAllOrders finished");
      })
      .catch((error) => {
        console.error("Error fetching all orders:", error);
        setIsBackgroundFetching(false);
      });
  };

  const mergeBase64Strings = async (base64StrArray) => {
    const mergedPdf = await PDFDocument.create();

    for (let i = 0; i < base64StrArray.length; i++) {
      const pdfFile = await PDFDocument.load(
        `data:application/pdf;base64,${base64StrArray[i]}`
      );

      const copiedPages = await mergedPdf.copyPages(
        pdfFile,
        pdfFile.getPageIndices()
      );
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfFile = await mergedPdf.save();
    // console.log("mergedPdfFile", mergedPdfFile);

    const mergedBase64 = btoa(String.fromCharCode(...mergedPdfFile));
    // console.log("mergedBase64", mergedBase64);
    return mergedBase64;
  };

  const downloadPDF = (pdf) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "labels.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handlePrintLabels = (tracking_num) => {
    tracking_num = tracking_num.join(",");
    // console.log("authentication", authentication);
    fetchLabels(tracking_num, authentication).then((response) => {
      // console.log("tracking", response.data.data[0].labels);
      handlePrintLabel(response.data.data[0].labels);
      // handleFetchOrders();
    });
  };

  // const handleDownloadLabels = (tracking_num) => {
  //   tracking_num = tracking_num.join(",");
  //   fetchLabels(tracking_num, authentication).then((response) => {
  //     // console.log("trackings_array", response.data);
  //     if (!_.isEmpty(response.data)) {
  //       const base64Strings = response.data.data.map((data) => data.labels);
  //       mergeBase64Strings(base64Strings).then((response) => {
  //         // console.log("response1", response);
  //         downloadPDF(response);
  //       });
  //     } else {
  //       return;
  //     }
  //   });
  // };

  const handleCancelLabels = (tracking_num) => {
    // console.log("auth", authentication);
    cancelLabels(tracking_num, authentication, cusID).then(() => {
      handleInitialFetchOrders();
    });
  };

  const handleDisplayOrderDetails = (row) => {
    row.toggleExpanded();

    // console.log("detail", row);
  };

  const renderRowActions = (row) => {
    let tracking = row.original?.tracking_num || false;
    if (!tracking) {
      return;
    }

    let refundSuccess = row.original?.refund_status === "success" || false;

    return (
      <Box display="flex">
        <IconButton
          disabled={refundSuccess}
          color="success"
          onClick={() => handlePrintLabels([row.original.tracking_num])}
        >
          <PrintIcon />
          <Typography variant="body2">&nbsp;Print</Typography>
        </IconButton>
        <IconButton
          disabled={refundSuccess}
          color="error"
          onClick={() => handleCancelLabels([row.original.tracking_num])}
        >
          <CancelIcon />
          <Typography variant="body2">&nbsp;Cancel</Typography>
        </IconButton>
        <IconButton color="info" onClick={() => handleDisplayOrderDetails(row)}>
          <AssignmentIcon />
          <Typography variant="body2">&nbsp;Details</Typography>
        </IconButton>
      </Box>
    );
  };

  const downloadLabelInSingleFile = (labelData) => {
    if (labelData && labelData.data && !_.isEmpty(labelData.data)) {
      const base64Strings = labelData.data.map((data) => data.labels);
      mergeBase64Strings(base64Strings).then((response) => {
        // console.log("response1", response);
        downloadPDF(response);
      });
    } else {
      return;
    }
  };

  const downloadLabelInMultipleFiles = async (labelData) => {
    if (!labelData || !labelData.data || _.isEmpty(labelData.data)) {
      return;
    }
    const zip = new JSZip();
    labelData.data.forEach((label) => {
      // console.log("label", label);
      const base64PDF = label.labels;
      const byteArray = atob(base64PDF);
      const uint8Array = new Uint8Array(byteArray.length);
      for (let i = 0; i < byteArray.length; i++) {
        uint8Array[i] = byteArray.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: "application/pdf" });
      zip.file(`${label[labelNameOptions]}.pdf`, blob);
    });
    const zipData = await zip.generateAsync({
      type: "blob",
      streamFiles: true,
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(zipData);
    link.download = "labels.zip";
    link.click();
  };

  const handleDownLoad = async () => {
    const tracking_num = downloadLabels.join(",");
    // console.log("tracking_num", tracking_num);
    let labelData = await fetchLabels(tracking_num, authentication);
    if (!labelData || labelData.status !== 200) {
      return;
    }
    labelData = labelData.data;
    // console.log("labelData", labelData);
    if (labelOptions === "single") {
      downloadLabelInSingleFile(labelData);
    } else {
      downloadLabelInMultipleFiles(labelData);
    }
    handleInitialFetchOrders();
    handleBatchLabelsModalClose();
  };

  const handleBatchGetLabels = async (table) => {
    setDownloadLabels([]);
    // console.log("table", table);
    const { rows } = table.getSelectedRowModel();
    // console.log("rows", rows);
    if (_.isEmpty(rows)) {
      handleSnackbarOpen();
      return;
    }

    const validRows = rows.filter(
      (row) => row.original.refund_status !== "success"
    );

    if (_.isEmpty(validRows)) {
      handleSnackbarOpen();
      return;
    }
    const selectedTrackings = validRows.map((row) => row.original.tracking_num);
    setDownloadLabels(selectedTrackings);
    handleBatchLabelsModalOpen();
  };

  const handleBatchCancelOrders = (table) => {
    const { rows } = table.getSelectedRowModel();
    if (_.isEmpty(rows)) {
      return;
    }
    const selectedTrackings = rows.map((row) => row.original.tracking_num);
    handleCancelLabels(selectedTrackings);
  };

  // Increment printTrigger to ensure the same label can be printed multiple times
  const handlePrintTrigger = () => {
    setPrintTrigger((prevValue) => prevValue + 1);
  };

  // useEffect(() => {
  //   handleFetchOrders();
  // }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (
      backgroundData.length > 0 &&
      !isBackgroundFetching &&
      !cancellationToken
    ) {
      setTableData(backgroundData); // Merge full data if background fetch completed
    }
  }, [backgroundData, isBackgroundFetching, cancellationToken]);

  useEffect(() => {
    if (backgroundData.length === 0) {
      handleInitialFetchOrders();
    }
    return () => setCancellationToken(true); // Set cancellation when component unmounts or dependencies change
  }, [pagination.pageIndex, pagination.pageSize]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <MaterialReactTable
          columns={columns}
          data={tableData}
          state={{ showProgressBars, showSkeletons }}
          // state={{ showProgressBars, showSkeletons, pagination }}
          enableRowNumbers
          enableRowActions
          enableRowSelection
          // manualPagination
          // onPaginationChange={setPagination}
          rowCount={rowCount}
          positionActionsColumn="last"
          filterFns={{
            myCustomFilterFn: (row, id, filterValue) => {
              if (row.getValue(id)) {
                const rowValue = row.getValue(id).toUpperCase();
                return rowValue.includes(filterValue.toUpperCase());
              }
              return false;
            },
          }}
          globalFilterFn="myCustomFilterFn"
          renderRowActions={({ row }) => renderRowActions(row)}
          renderTopToolbarCustomActions={({ table }) => (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <IconButton onClick={handleInitialFetchOrders}>
                <RefreshIcon></RefreshIcon>
              </IconButton>
              <Button
                color="success"
                onClick={() => handleBatchGetLabels(table)}
                variant="contained"
              >
                Batch Get Labels
              </Button>
              <Button
                color="error"
                onClick={() => handleBatchCancelOrders(table)}
                variant="contained"
              >
                Batch Cancel Orders
              </Button>
            </div>
          )}
          renderDetailPanel={({ row }) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* {JSON.stringify(row.original.order_data, null, 4)} */}
              <ReactJson src={row.original.order_data} theme="monokai" />
            </Box>
          )}
          muiTableProps={{
            sx: {
              border: "1px solid rgba(81, 81, 81, 1)",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              border: "1px solid rgba(81, 81, 81, 1)",
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              border: "1px solid rgba(81, 81, 81, 1)",
            },
          }}
        />

        {currentLabel && (
          <PrintPDF base64PDF={currentLabel} printTrigger={printTrigger} />
        )}
      </ThemeProvider>
      <Modal open={batchLabelsModalOpen} onClose={handleBatchLabelsModalClose}>
        <Box sx={boxStyle}>
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
                Select Label File Options
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "2rem",
              }}
            >
              <FormControl>
                <FormLabel id="file-option-label">File Option</FormLabel>
                <RadioGroup
                  aria-labelledby="file-option-radio-buttons-group"
                  name="file-option-radio-buttons-group"
                  value={labelOptions}
                  onChange={handleLabelOptions}
                >
                  <FormControlLabel
                    value="single"
                    control={<Radio />}
                    label="Single PDF File"
                  />
                  <FormControlLabel
                    value="multiple"
                    control={<Radio />}
                    label="Separate PDF Files (Zipped)"
                  />
                </RadioGroup>
              </FormControl>
              {labelOptions === "multiple" && (
                <FormControl>
                  <FormLabel id="file-name-option-label">
                    Each PDF file named as:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="file-name-option-radio-buttons-group"
                    name="file-name-option-radio-buttons-group"
                    value={labelNameOptions}
                    onChange={handleLabelNameOptions}
                  >
                    <FormControlLabel
                      value="tracking_num"
                      control={<Radio />}
                      label="Tracking Number"
                    />
                    <FormControlLabel
                      value="customer_order_num"
                      control={<Radio />}
                      label="Customer Order Number"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              sx={{ marginTop: "2rem" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleBatchLabelsModalClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownLoad}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Please select at least one valid/un-refunded order to batch get labels
        </Alert>
      </Snackbar>
    </>
  );
}

export default OrderTable;
